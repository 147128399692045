<template>
    <div class="application">
        <img src="../assets/YYZX.png" style="width:100%;" alt="">
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>